<template>
  <div class="d-flex flex-column flex-root vh100">
    <div class="d-flex flex-column flex-lg-row flex-column-fluid">

      <!--begin::Body-->
      <div class="d-flex flex-column flex-lg-row-fluid vh100 position-relative"
           :class="{'after-triangle':!isMobile}">
        <div class="d-flex flex-center flex-column flex-column-fluid">
          <div class="card card-flush rounded-5" :class="{'w-100':isMobile}">
            <div class="card-body">
              <div class="mb-10">
                <img alt="Logo" src="@/assets/media/logos/logo.svg"
                     class="mx-auto d-block mh-50px"/>
              </div>
              <router-view/>
            </div>
          </div>
        </div>

        <div class="d-flex flex-center flex-wrap p-5 pb-0">
          <Credits />
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import {defineAsyncComponent} from "vue";

export default {
  name: 'AuthLayout',
  components: {
    Credits: defineAsyncComponent(() => import('@/components/CreditsComponent.vue')),
  },
  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      isMobile: 'isMobile',
    }),
  },
};
</script>

<style scoped>
.after-triangle:after {
  content: " ";
  position: absolute;
  top: 50%;
  right: -40px;
  margin-top: -25px;
  border-width: 25px;
  border-style: solid;
  border-color: transparent transparent transparent white;
}
</style>
